// src/Contact.js
import React, { useState } from 'react';
import { Button, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import ContactForm from './ContactForm'; // Ensure the correct path
import { Modal } from 'react-bootstrap'; // Import from react-bootstrap
import './Contact.css';
import Footer from './Footer';


const Contact = () => {
    const [showForm, setShowForm] = useState(false);
  
    // const handleEnquiryClick = () => {
    //   setShowForm(true); // Show the modal
    // };
  
    // const handleClose = () => {
    //   setShowForm(false); // Hide the modal
    // };
  
    return (
      <div>
        <Grid item container sx={{ backgroundColor: '#D2DCFF' }}>
        
          <Grid item container direction="row" className='pb-5 mt-5'>
            <Grid item container xs={12} md={12} >
              <ContactForm/>
            </Grid>
           </Grid>
        </Grid>
  
        {/* Modal for Contact Form */}
        {/* <Modal show={showForm} onHide={handleClose} dialogClassName="modal-dialog-centered">
          <Modal.Header closeButton>
            <Modal.Title>Contact Us</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ContactForm onClose={handleClose}/>
          </Modal.Body>
          
        </Modal> */}
        <Footer/>
      </div>
    );
  };
export default Contact;
