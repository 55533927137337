// src/ContactForm.js
import React, { useState } from 'react';
import { TextField, Button, Grid, Typography, Container, InputLabel, Select, FormControl, MenuItem } from '@mui/material';


const ContactForm = ({onClose}) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    contactNo: '',

    type: 'feedback',
    additionalInfo: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_PUBLIC_BASE_URL}/sendContactEmail`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      alert('Email sent successfully!');
      // Clear form data after successful submission
      setFormData({
        name: '',
        email: '',
        contactNo: '',       
        type: '',
        additionalInfo: '',
      });
      onClose();
    } catch (error) {
      console.error('There was an error sending the email:', error);
    }
  };

  return (
    <Container sx={{border: '1px solid #FFF', backgroundColor: '#FFF', paddingTop: '10px', paddingBottom: '10px', borderRadius: '22px'}}>
     <Typography variant="h3" gutterBottom>
        Contact Us
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              label="Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              fullWidth
              required
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Email"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              fullWidth
              required
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Contact No"
              name="contactNo"
              value={formData.contactNo}
              onChange={handleChange}
              fullWidth
              required
              variant="outlined"
            />
          </Grid>
          
          <Grid item xs={12} md={6}>
        <FormControl fullWidth variant="outlined">
          <InputLabel id="type-label">Type</InputLabel>
          <Select
            labelId="type-label"
            label="Type"
            name="type"
            value={formData.type}
            onChange={handleChange}
            required
          >
            <MenuItem value="feedback">Feedback</MenuItem>
            <MenuItem value="complaint">Complaint</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      {formData.type && (
        <Grid item xs={12}>
          <TextField
            label={`${formData.type.charAt(0).toUpperCase() + formData.type.slice(1)} Details`}
            name="additionalInfo"
            value={formData.additionalInfo}
            onChange={handleChange}
            fullWidth
            required
            variant="outlined"
            multiline
            rows={4}
          />
        </Grid>
      )}
          <Grid item xs={12}>
            <Button type="submit" variant="contained" sx={{backgroundColor: '#8e7fef'}}>
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default ContactForm;
