import React, { useEffect } from 'react';

const SubscriptionButton = ({ buttonId, plan}) => {
  const storedUser = JSON.parse(localStorage.getItem('user')) || {};
  const parentname = storedUser.parentname || '';
  const id = storedUser.id || '';
  const email = storedUser.email || '';
  const ageGroup = localStorage.getItem('selectedAgeGroup') || 'default-ageGroup'; // Get from local storage
  console.log(ageGroup);
  useEffect(() => {
    if (!buttonId) return;

    const script = document.createElement('script');
    script.src = "https://cdn.razorpay.com/static/widget/subscription-button.js";
    
    // Required parameters
    script.setAttribute('data-subscription_button_id', buttonId);
    script.setAttribute('data-key', process.env.KEY_LIVE_ID);
    
    // Optional parameters
    script.setAttribute('data-button_theme', 'brand-color');
    script.setAttribute('data-button_text', 'Subscribe Now');
    script.setAttribute('data-button_background_color', '#4CAF50');
    script.setAttribute('data-button_text_color', '#FFFFFF');
    
    // Customer information
    script.setAttribute('data-customer_id', id);
    script.setAttribute('data-prefill.name', parentname);
    script.setAttribute('data-prefill.email', email);
    script.setAttribute('data-prefill.contact', '+919876543210');
    
    // Additional options
    script.setAttribute('data-notes.plan', plan);
    script.setAttribute('data-notes.ageGroup', ageGroup);
    
    script.async = true;
    
 

    const form = document.getElementById('razorpay-subscription-form');
    if (form) {
      form.innerHTML = '';
      form.appendChild(script);
    }

    return () => {
      if (form) {
        form.innerHTML = '';
      }
    };
  }, [buttonId, plan, ageGroup]);

  return (
    <form id="razorpay-subscription-form">
      <input type="hidden" name="ageGroup" value={ageGroup} />
      {/* The Razorpay subscription button will be injected here */}
    </form>
  );
};

export default SubscriptionButton;



