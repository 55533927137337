import { Grid, Card, CardContent, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import SubscriptionButton from "./SubscriptionButton";

const planMapping = {
  '3-month': 'basic',
  '6-month': 'standard',
  '12-month': 'premium'
};

const subscriptionButtonIds = {
  '3-month': 'pl_Oh5zDbk5A8h1Bl',
  '6-month': 'pl_Oh63d8qY3pwgW4',
  '12-month': 'pl_Ofwg1uXrVPXIyr'
};


function Checkout() {
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const ageGroup = searchParams.get('ageGroup');
  const plan = searchParams.get('plan');




  const buttonId = subscriptionButtonIds[plan];




  return (
    <div>
      <Grid container className="pt-4" style={{backgroundColor: '#FFF8BD'}}>
        <Grid item container xs={3} md={3} justifyContent="center" alignItems="center">
          <img src="/assets/image 25.png" alt="" style={{height: '160px'}}/>
        </Grid>
        <Grid item container xs={6} md={6} justifyContent="center" alignItems="center">
          <div className="text-center">
            <h4 style={{fontSize: '2.1rem', color: '#4A66C9', fontWeight: '600'}}>Unlock a World of Play!</h4>
            <p style={{fontSize: '2.6rem', color: '#000', fontWeight: '500'}}>Explore, Learn, and Grow with Our <br></br> Curated Toy Collection.</p>
          </div>
        </Grid>
        <Grid item container xs={3} md={3} justifyContent="center" alignItems="center">
          <img src="/assets/image 26.png" alt="" style={{height: '160px'}}/>
        </Grid>
      </Grid>

      <Grid item container md={12} xs={12} justifyContent="center" className="mt-5 mb-5">
        <Card container style={{backgroundColor: 'rgb(182 196 245)'}}>
          <CardContent>
            <Typography variant="h4" gutterBottom className="text-center" style={{fontWeight: '700'}}>
              Ready to Checkout
            </Typography>
            <hr></hr>
            
            <Grid container justifyContent="center" style={{ marginTop: '20px' }}>
              {/* Razorpay button form */}
              {buttonId && <SubscriptionButton buttonId={buttonId} plan={plan} ageGroup={ageGroup} />}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </div>
  );
}

export default Checkout;
